import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import CaseStudiesDetailsContent from '../components/CaseStudiesDetails/CaseStudiesDetailsContent'
import RelatedProjects from '../components/CaseStudiesDetails/RelatedProjects'

const CaseStudyTemplate = (props) => {
    let data = props.data.wpgraphql;

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://w3vitals.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://w3vitals.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }
    
    return (
        <Layout metaData={constructMetaData(data.caseStudy, props.pageContext.currentPage, props.pageContext.language)}>
            <Navbar header={props.pageContext.headerMenu}  lang={props.pageContext.language} />
            <PageBanner
                pageTitle={data.caseStudy.title} 
                homePageText={props.pageContext.language ==="EN" ? "Home" : (props.pageContext.language ==="FR" ? "Accueil" : "Αρχική")}
                homePageUrl={props.pageContext.language ==="EN" ? "/en/" : (props.pageContext.language ==="FR" ? "/fr/" : "/")}
                prevPageText="Case Studies"
                prevPageUrl="/case-studies/"
                activePageText={data.caseStudy.title} 
            />
            <CaseStudiesDetailsContent 
                caseStudy={data.caseStudy}
            />
            {/* <RelatedProjects /> */}
            <Footer footer={props.pageContext.footerMenu} extra={data.footerTemplate.footerTemplateAcf}/>
        </Layout>
    );
}

export default CaseStudyTemplate

export const pageQuery = graphql`
    query GET_CASE_STUDY($id: ID!, $language: WPGraphQL_LanguageCodeFilterEnum, $parentSlug: ID!, $footerTemplateUri: ID!) {
        wpgraphql {
            caseStudy(id: $id){
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
                title
                content
                uri
                caseStudyAcf{
                    nextCaseStudyLabel
                    previousCaseStudyLabel
                    featuredImage{
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 790, quality: 100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }

                    logoImage{
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 300, quality: 100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }

                    infoDetails{
                        clientDetails{
                            label
                            content
                            image{
                                altText
                                sourceUrl
                                imageFile{
                                    childImageSharp {
                                        fixed(width: 25, height:25){
                                            base64
                                            width
                                            height
                                            src
                                            srcSet
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            caseStudies(where: {language: $language}) {
                edges {
                    node {
                        id
                        uri
                        title
                    }
                }
            }
            page(idType: URI, id:$parentSlug ){
                breadcrumbAcf{
                    breadcrumbName
                }
                slug
            }
            footerTemplate: template(id: $footerTemplateUri, idType: SLUG) {
                footerTemplateAcf{
                    addressTitle
                    addressContent
                    content
                }
            }
        }
    }
`

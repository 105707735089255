import React from 'react'
import Img from "gatsby-image"


const CaseStudiesSidebar = (props) => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <div className="pb-3">
                    <Img fluid={props.logo.imageFile.childImageSharp.fluid} alt={props.logo.altText} style={{width: "135px",margin: "auto"}} />
                </div>
                <ul>
                    {props.details.map((dt, i) => (
                        <li>
                            <div className="row relative">
                                <div style={{ position: "absolute", left: "0" }}>
                                    <Img fixed={dt.clientDetails.image.imageFile.childImageSharp.fixed} alt={dt.clientDetails.image.altText} style={{ top: "4px" }} />
                                </div>
                                <span style={{ paddingLeft: "2.5rem" }}>{dt.clientDetails.label}:</span>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: dt.clientDetails.content }}></div>

                        </li>
                    ))}
                </ul>
            </div>
        </div >
    )
}

export default CaseStudiesSidebar
import React, { useEffect, useState, useCallback } from 'react'
import CaseStudiesSidebar from './CaseStudiesSidebar'
import ImageViewer from "react-simple-image-viewer";
import Img from "gatsby-image"


const CaseStudiesDetailsContent = (props) => {

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [images, setImages] = useState([])

    const [ customOpener, setCustomOpener] = useState("");

    const openImageViewer = useCallback(index => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    useEffect(() => {
        var elements = document.getElementsByClassName("blocks-gallery-item");

        var myFunction = function (e) {
            // var attribute = this.getAttribute("data-myattribute");
            // openImageViewer(images.indexOf(e.target.currentSrc))

            setCustomOpener(e.target.currentSrc)

            console.log(e);
            console.log(images.indexOf(e.target.currentSrc))

        };

        let tmpGallery = [];
        for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener('click', myFunction, false);
            console.log(elements[i].children[0].children[0].src)
            if( elements[i]?.children[0]?.children[0]?.src ){
                tmpGallery.push(elements[i]?.children[0]?.children[0]?.src)
            }
        }
        setImages(tmpGallery);
    }, [])

    useEffect(() => {
        if(customOpener !== ""){
            openImageViewer(images.indexOf(customOpener))
        }
    },[customOpener])


    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <Img 
                                fluid={props.caseStudy.caseStudyAcf.featuredImage.imageFile.childImageSharp.fluid} 
                                alt={props.caseStudy.caseStudyAcf.featuredImage.altText} 
                                style={{objectFit: "cover",width: "100%",height: "100%"}}
                                className="bg-position" 
                            />
                            {/* <picture>
                                <source type="image/webp" srcSet={props.caseStudy.caseStudyAcf.featuredImage.imageFile.childImageSharp.fluid.srcSetWebp} importance="high" />
                                <source type="image/jpg" srcSet={props.caseStudy.caseStudyAcf.featuredImage.imageFile.childImageSharp.fluid.srcSetWebp} />
                                <img
                                    alt={props.caseStudy.caseStudyAcf.featuredImage.altText}
                                    importance="high"
                                    style={{ objectFit: "cover", width: "100%", height: "100%" }}
                                    className="bg-position"
                                />
                            </picture> */}
                        </div>
                        <div className="case-studies-details-desc" dangerouslySetInnerHTML={{ __html: props.caseStudy.content }}></div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        {props.caseStudy.caseStudyAcf.infoDetails && (
                            <CaseStudiesSidebar
                                details={props.caseStudy.caseStudyAcf.infoDetails}
                                logo = {props.caseStudy.caseStudyAcf.logoImage}
                            />
                        )}
                    </div>
                </div>
            </div>

            {isViewerOpen && (
                <ImageViewer
                    src={images}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)"
                    }}
                />
            )}

        </section>
    )
}

export default CaseStudiesDetailsContent